import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card,Header,Megacard,Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { FaCheckCircle } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function Become_mentor(props) {


    return (
        <Section >

            <Container style={{color:"#2D0E4A"}}  width="narrow2">

            <Flex gap={4} variant="responsive">
                        <Box>
                            <Subhead>
                                <span style={{ color: "#1C97EF" }}>Become a mentor</span> <br/> with Bizzlab<StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{marginTop:"1%"}} />
                            </Subhead>

                        </Box>
                    </Flex>
                     <Megacard >
                     <Header >
                     <StaticImage src='../../../images/mentor_images/3_dots.png' alt="3_dots" width={75}/>
                     </Header>
                    <Container style={{ color: "#0B132A" }}>
                        <Flex gap={4} variant="responsive">
                            <Box>
                                <Tabs>
                                    <TabList>
                                        <Tab>Mentors Driven Model</Tab>
                                        <Tab>Eligibility</Tab>
                                        <Tab>Key Responsibility</Tab>
                                        <Tab>Remunertion</Tab>
                                    </TabList>

                                    <TabPanel>
                                       
                                       <ul>
                                           <li><Text>Mentors are Strategic Partners of our platform.</Text></li>
                                           <li><Text>They are leaders of our platform who drives the students in right path and ensure they get the right knolwedge.</Text></li>
                                           <li><Text>They undertake the guiding role and bring awareness to student and thereby facilitates the admission process in to the platform.</Text></li>
                                           <li><Text>Monitoring of Job Experience is also done by Mentor</Text></li>
                                       </ul>
                                    </TabPanel>
                                    <TabPanel>
                                        <ul>
                                        <li> <Text>Commerce Lecturers in any college </Text></li>
                                        <li> <Text>Principals/Vice Principals </Text></li>
                                        <li> <Text>HOD of Commerce in any college </Text></li>
                                       <li> <Text>Chartered Accountants/ Company Secretaries / Cost & Management Accountants	 </Text></li>
                                       <li> <Text>Faculties or Owners of Commerce Tuition Academies or Coaching Centers </Text></li>
                                       <li> <Text>Owners of Commerce Colleges </Text></li>
                                       </ul>
                                    </TabPanel>
                                    <TabPanel>
                                       <ul>
                                       <li> <Text>Student Admission into the portal	 </Text></li>
                                       <li> <Text>Monitoring their learing progress in the platform </Text></li>
                                       </ul>
                                    </TabPanel>
                                    <TabPanel>
                                       <ul>
                                       <li> <Text>Register Online </Text></li>
                                       <li> <Text>Submit documents </Text></li>
                                       <li> <Text>Get login credentials </Text></li>
                                       <li> <Text>Start admitting students through approved Log-In	 </Text></li>
                                       <li> <Text>Get remuneration on monthly basis</Text></li>
                                       </ul>
                                    </TabPanel>
                                </Tabs>

                            </Box>

                        </Flex>

                    </Container>
                </Megacard>
            </Container>
        </Section>
    )
}
