import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card,Header, Button, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { BiChevronRight } from "react-icons/bi";
import {platform_btn, text_justify} from '../../ui.css'


export default function Login_creation(props) {


    return (
        <Section >

            <Container style={{color:"#081F32"}}  width="narrow2">

            <Flex gap={4} variant="responsive">
                        <Box>  
                         <Container>
                       <Text> <Button className={platform_btn}><StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} />How to Access our Platform</Button></Text>
                        <Subhead>Login Creation	</Subhead>
                        <Text className={text_justify}>The first step to access our platform is to create login credentials. Please click below to create login. Mentor need to provide few details in the login screen and after successful submission, login credential will be shared to your email. Using those credentials you can login and view the platform.</Text>
                        <Button>Create Login < BiChevronRight/> </Button>
                        </Container>
                        </Box>
                        <Box>
                        <StaticImage src="../../../images/mentor_images/mentor_img3.png" alt="mentor_img3"  />
                        </Box>
                    </Flex>
                    
            </Container>
        </Section>
    )
}
