import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card, Header, Button, Subhead, FlexList } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { BiChevronRight, BiUserCircle } from "react-icons/bi";
import { AiOutlineFileText } from "react-icons/ai";
import { BsFillCalendarEventFill, BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";
import { use_platform, use_platform_cards, spancolor_9, gardient_btns, paddingTop, marginTop,marginTop_5, marginBottom } from '../../ui.css';

export default function Use_the_platform(props) {
    return (
        <Section className={use_platform}>
            <Container style={{ transform: "skewY(5deg)" }} className={paddingTop} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box center>
                        <Subhead> <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "2%" }} /> <span className={spancolor_9}>How</span> To use the Platform</Subhead>
                        <Text>Job Experience monitored through mentors
                        </Text>
                        <Text> <StaticImage src="../../../images/mentor_images/mentor_img2.png" alt="mentor_img2" /></Text>
                        <Text center >Once mentor login to the platform, <br/>
                        they can see the following Menu at the left side of the screen. </Text>
                        <Text><br/> </Text>
                    </Box>
                </Flex>
                <Flex gap={4} variant="responsive" className={marginBottom}>
                    <Box>
                        <Container center>
                            <Card className={use_platform_cards}>
                                <FlexList>
                                    <li><StaticImage src="../../../images/student_enrollments.png" alt="student_enrollments" width={40} /> </li>
                                    <li>Student enrollments </li>
                                </FlexList>
                            </Card>
                            <Card className={use_platform_cards}>
                                <FlexList>
                                    <li><StaticImage src="../../../images/collection_payment.png" alt="collection_payment" width={40} /></li>
                                    <li>Collection & Payment</li>
                                </FlexList>
                            </Card>
                            <Card className={use_platform_cards}>
                                <FlexList>
                                    <li><StaticImage src="../../../images/dashboard.png" alt="skill_elevator_icon" width={40} /></li>
                                    <li> Dashboard</li>
                                </FlexList>
                            </Card>
                            <Card className={use_platform_cards}>
                                <FlexList>
                                    <li><StaticImage src="../../../images/registration.png" alt="skill_elevator_icon" width={40} /></li>
                                    <li>Registration</li>
                                </FlexList>
                            </Card>
                        </Container>
                    </Box>
                    <Box>
                        <Container>
                            <Text>Using student enrolment menu, mentor can refer new students/subscribers. Upon submission of details, Platform will send a link to the student. Using such link, student can subscribe to the platform.</Text>
                            <Text>Status of student Registration , payment, module progress etc can be seen by the Mentor.</Text>
                            <Text>Upon successful enrollment, Mentor get remuneration on monthly basis as per the signed agrement. They can see the amount earned and amount received in Mentor Dashboard.</Text>
                            <Button className={`${gardient_btns} ${marginTop}`}>Contact us for More Details< BiChevronRight /></Button>
                        </Container>
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
