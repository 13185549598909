import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo";
import Mentor_hero from "../components/associates/mentor/mentor_hero";
import Faculitys from "../components/associates/mentor/faculities";
import Academy_faculity from "../components/associates/mentor/academy_faculity";
import Any_ca_mentor from "../components/associates/mentor/any_ca_mentor";
import Become_mentor from "../components/associates/mentor/become_mentor";
import Login_creation from "../components/associates/mentor/login_creation";
import Registration from "../components/associates/mentor/registration";
import Role_of_mentor from "../components/associates/mentor/role_of_mentor";
import Use_the_platform from "../components/associates/mentor/ues_the_platform";


const Mentor = () => (
  <Layout navName="Mentor">
    <Seo title="Mentor" />
    <Mentor_hero />
    <Faculitys />
    <Academy_faculity />
    <Any_ca_mentor />
    <Become_mentor />
    <Login_creation />
    <Registration />
    <Role_of_mentor />
    <Use_the_platform />
  </Layout>
)

export default Mentor
