import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Card, PlatformHeading, PlatformSubhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { mentor_hero, mentor_hero_img } from "../../ui.css"
export default function Mentor_hero(props) {

    return (
        <>
            <Section className={mentor_hero}  >
                <Container  width="narrow2">
                    <Flex gap={4} variant="responsive" >
                        <Box>
                            <PlatformHeading>
                                Mentor
                            </PlatformHeading>
                            <PlatformSubhead>
                                Job Experience monitored through mentors
                            </PlatformSubhead>
                        </Box>
                    </Flex>
                </Container>
            </Section>
            <Section></Section>
         
                <Container className={mentor_hero_img}  width="narrow2">
                    <StaticImage src='../../../images/mentor_images/mentor_banner.png' alt="mentor_banner" />
                </Container>
           
        </>
    )
}
