import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, FlexList, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { FaCheckCircle } from "react-icons/fa";
import { spancolor } from '../../ui.css'
export default function Faculitys(props) {
    return (
        <Section style={{ color: "#2D0E4A" }}>
            <Container  width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Subhead>
                            <span className={spancolor}>  College Faculties </span><br />as  Mentors	<StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "2%" }} />
                        </Subhead>
                    </Box>
                    <Box></Box>
                </Flex>
            </Container>
            <Container  width="narrow2" >
                <Flex gap={4} variant="responsive">
                    <Box>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li> <Text> Any commerce faculty or commerce HOD or Principal of a college can act as Mentor for Skill Development.	</Text></li>
                        </FlexList>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /></Text></li>
                            <li> <Text>Mentors will guide all commerce students in that college.</Text></li>
                        </FlexList>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li>   <Text> Mentors give an introduction to the BizzLab and Refer to the platform by entering into the details of the students.</Text></li>
                        </FlexList> <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li> <Text> Upon successful submission of student details, a link will be shared where student can enable their registration process.</Text></li>
                        </FlexList>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li>  <Text>Mentors should always encourage students to finish their courses on time and move on to the next level.</Text></li>
                        </FlexList>
                    </Box>
                    <Box>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li>  <Text> MENTOR can monitor the progress of the student training through the BizzLab platform.. </Text> </li>
                        </FlexList>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /><br/>&nbsp;</Text></li>
                            <li> <Text> Mentors receive remuneration on a monthly basis following the successful onboarding of their students.. </Text> </li>
                        </FlexList>
                        <FlexList >
                            <li><Text className={spancolor}> <FaCheckCircle /></Text></li>
                            <li><Text> Student can always reach a mentorfor any questions.. </Text>  </li>
                        </FlexList>
                        <FlexList >
                            <li></li>
                            <li><Text> <StaticImage src='../../../images/mentor_images/mentor_img1.png' alt="mentor_img1" width={300}  style={{position:"relative" , top:"22px"}}/> </Text>  </li>
                        </FlexList>
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
