import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card, FlexList, Subhead,Megacard, Button, Head } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { FaCheckCircle } from "react-icons/fa";
 import {card_background,black_1,register_btn} from '../../ui.css'
export default function Any_ca_mentor(props) {
    return (
        <Section style={{
            marginTop: "-9%",
            position: "relative"
        }}>
            <Container  width="narrow2">
               
                 <Megacard style={{ background: "linear-gradient(280.25deg, #60A2FE 6.83%, #4847ED 42.13%, #8C27D7 73.52%)", boxShadow: "30px 30px 50px rgba(0, 0, 0, 0.25)", color: "#fff", positon: "relative" }}>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Container>
                            <Head>
                                Any CA<StaticImage src="../../../images/Skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={40} style={{ marginTop: "2%" }} /><br />can act as Mentor
                            </Head>
                            </Container>
                        </Box>
                        <Box>
                            <StaticImage src='../../../images/mentor_images/mentor_img2.png' alt="mentor_img2" style={{marginTop:'-18%'}}/>
                        </Box>
                    </Flex>
                    <Container >
                        <Flex gap={4} variant="responsive">
                            <Box>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li> <Text>Chartered Accountant in practice or in employment can act as Mentor to refer the Skill Development programme.</Text></li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /></Text></li>
                                    <li><Text> Mentors will guide all commerce students in that college.</Text></li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li> <Text>  Mentors give an introduction to the BizzLab and referrer to the platform by entering in to the details of the students.</Text></li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li> <Text>   Upon successful submission of student details, a link will be shared where student can enable their registration process.</Text></li>
                                </FlexList>
                            </Box>
                            <Box>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li> <Text>MENTOR can monitor the progress of the student training through the BizzLab platform.  </Text> </li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li> <Text>Mentors receive remuneration on a monthly basis following the successful onboarding of their students.  </Text> </li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /></Text></li>
                                    <li>   <Text>Student can always reach Mentor for any questions.  </Text></li>
                                </FlexList>
                                <FlexList>
                                    <li><Text><FaCheckCircle /><br/>&nbsp;</Text></li>
                                    <li>
                                        <Text>Mentors should always encourage students to finish their courses on time and move on to the next level.  </Text> </li>
                                </FlexList>
                            </Box>
                        </Flex>
                        <Section>
                            <Flex gap={4} variant="responsive">
                            <Box>
                                <Card className={card_background}>
                                    <FlexList  gap={1} variant="responsive">
                                        <li> <span className={black_1}>Join as mentor-driven approach </span></li>
                                        <li> <Button className={register_btn}>REGISTER</Button></li>
                                        </FlexList>                        
                                </Card>
                            </Box>
                            <Box></Box>
                            </Flex>
                        </Section>
                    </Container>
                </Megacard>

            </Container>

        </Section>
    )
}
