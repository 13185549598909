import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card, Header, Button, Subhead, Megacard } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { BiChevronRight } from "react-icons/bi";
import { mentor_card, lightcolor_6, paddingTop, text_justify } from "../../ui.css"
export default function Role_of_mentor(props) {
    return (
        <Section>
            <Container className={paddingTop} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <StaticImage src="../../../images/mentor_images/mentor_img5.png" alt="mentor_img5" />
                    </Box>
                    <Box >
                        <Megacard className={mentor_card} >
                            <Container>
                                <Subhead> Role of Mentor  <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "2%" }} /></Subhead>
                                <Text className={`${lightcolor_6} ${text_justify}`}>
                                The role of a mentor is to invite new subscribers into the platform from their college or known network. The mentor should give complete details and benefits about the Bizzlab platform and encourage them to get Job-based learning and experience along with their study. Mentor has a responsibility to overseas the progress of subscriber's learning. Mentor gets attractive remuneration for the above role.
                                </Text>
                                <Button>Start Now <BiChevronRight /></Button>
                            </Container>
                        </Megacard>
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
