import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead, FlexList } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { FaCheckCircle } from "react-icons/fa";
import{spancolor,accdamy_faculty,spancolor_9, marginBottom, marginTop, marginBottom_1, marginBottom_2, marginBottom_3, marginBottom_4, marginBottom_5} from '../../ui.css'
export default function Academy_faculity(props) {
    return (
        <Section className={accdamy_faculty}>
            <Container style={{ transform: "skewY(6deg)" }}  width="narrow2">
                <Flex gap={6} variant="responsive">
                    <Box>
                        <Subhead >
                        {/* <StaticImage src="../../../images/Skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={30} style={{ marginTop: "1%" }} /> */}
                            <Container>
                         <span className={spancolor_9}>  Academy / Tuition /<br />Coaching Centre Faculties </span>as Mentor
                         </Container>
                        </Subhead>
                    </Box>
                  
                </Flex>
                <Container >
                    <Flex gap={4} variant="responsive" className={`${marginTop} ${marginBottom_5}`}>
                        <Box>
                            <FlexList>
                                <li><Text className={spancolor_9}><FaCheckCircle /><br/>&nbsp;</Text></li>
                                <li> <Text>   Any commerce faculty in any tuition centre or academy of commerce can act as Mentor for Skill Development.</Text></li>
                            </FlexList>
                            <FlexList>
                                <li><Text className={spancolor_9}><FaCheckCircle /><br/>&nbsp;</Text></li>
                                <li><Text>A mentor will guide all commerce students of the college in that Academy or Tuition Center.</Text></li>
                            </FlexList>
                            <FlexList>
                                <li><Text className={spancolor_9}><FaCheckCircle /><br/>&nbsp;</Text></li>
                                <li> <Text> Mentors give an introduction to the BizzLab and Refer to the platform by entering in to the details of the students.</Text></li>
                            </FlexList>
                            <FlexList>
                                <li><Text><FaCheckCircle className={spancolor_9}/><br/>&nbsp;</Text></li>
                                <li> <Text>  Upon successful submission of student details, a link will be shared where student can enable their registration process.</Text></li>
                            </FlexList>
                        </Box>
                        <Box>
                            <FlexList>
                                <li><Text><FaCheckCircle className={spancolor_9}/><br/>&nbsp;</Text></li>
                                <li> <Text> MENTOR can monitor the progress of the student training through the BizzLab platform.  </Text> </li>
                            </FlexList>
                            <FlexList>
                                <li><Text><FaCheckCircle className={spancolor_9}/><br/>&nbsp;</Text></li>
                                <li> <Text> Mentors receive remuneration on a monthly basis following the successful onboarding of their students.  </Text> </li>
                            </FlexList>
                            <FlexList>
                                <li><Text><FaCheckCircle className={spancolor_9}/></Text></li>
                                <li>  <Text> Student can always reach Mentor for any questions.  </Text></li>
                            </FlexList>
                            <FlexList>
                                <li><Text><FaCheckCircle className={spancolor_9}/><br/>&nbsp;</Text></li>
                                <li> <Text> Mentors should always encourage students to finish their courses on time and move on to the next level.  </Text> </li>
                            </FlexList>
                        </Box>
                    </Flex>
                </Container>
            </Container>
        </Section>
    )
}
