import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, SubheadSmall, Card, Header, Button, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { BiChevronRight } from "react-icons/bi";
export default function Registration(props) {
    return (
        <Section style={{ background: "radial-gradient(88.77% 278.19% at 12.81% -89.61%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#fff", transform: "skewY(-5deg)" }}>
            <Container style={{ transform: "skewY(5deg)" }} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Container>
                            <Subhead> Registration <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "2%" }} /></Subhead>

                            <Text>The next step is to register on the platform. The registration process is very simple. Click on the registration menu in the platform and fill in the details. Provide your name, address, qualification, College details, etc., and upload your photo. Mentor will also require to signup the agreement.<br />
                                After that click submit button to proceed with the registration.
                                Mentor will get the registration confirmation and registration number to his/her email ID. Using Those credentials, Mentor can proceed to use the platform.
                            </Text>
                        </Container>
                    </Box>
                    <Box>
                        <StaticImage src="../../../images/mentor_images/mentor_img4.png" alt="mentor_img4" />
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
